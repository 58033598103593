'use client';

import React, {
    useState,
    useEffect,
    useRef,
    MouseEvent as ReactMouseEvent,
} from 'react';

import { styled, alpha } from '@mui/material/styles';
import Link from 'next/link';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Image from 'next/image';
import TopBrands from '../header/topbrands';
import MobileSidebar from '../header/mobile-sidebar/mobile-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, useAppSelector } from '@/store/store';
import { SetCategoryId } from '@/store/slices/categorySlice';
import { useRouter } from 'next/navigation';
import { SetElasticSearch } from '@/store/slices/home-slice';
import { ClearCurrentPage, SetStoreId } from '@/store/slices/product-slice';
import { searchData } from '@/store/slices/actions/home-action';
import { getCategoryData } from '@/store/slices/actions/category-action';
import { Stack } from '@mui/material';
import { getAllBrandData } from '@/store/slices/actions/brand-action';
import {
    setClearProductFilter,
    setHeaderFilter,
    setIsDiscountFilter,
    setIsExpanded,
} from '@/store/slices/filter-slice';
import { createEncodedURL } from '@/utils/utilities';
import { getCookie, setCookie } from 'cookies-next';
import { getCartItemCount } from '@/store/slices/actions/cart-actions';
import SearchBar from '../header/search-bar';
import DeviceType from '../home/device-type';
import IPAddress from '../home/ip-address';
import userService from '@/services/userService';
import { getWistlistData } from '@/store/slices/actions/wishlist-action';
import LocationComponent from '../home/location-access';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import TransluscentOnMount from '../transluscent-on-mount';
import StoreListDrawer from '../common/store-list-drawer';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.1),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.2),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

interface LinkProps {
    href: string;
    children: React.ReactNode;
}

interface Category {
    _id: string;
    name: string;
    slug: string;
    populatedChildren: Category[];
}

// Search bar styling

export default function Header() {
    const [showCatDiv, setShowCatDiv] = useState<string>('');
    const [showBrandDiv, setShowBrandDiv] = useState<boolean>(false);
    const divRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const router = useRouter();
    const categories = useAppSelector(
        (state) => state.category_store.categories
    );
    const searchSuggestions = useSelector(
        (state: any) => state.home_store.searchSuggestions
    );
    const { cartCount } = useSelector((state: any) => state.cart_store);
    const storeId = useSelector((state: any) => state.product_store.storeId);
    const token = getCookie('accessToken');
    const wishListDataCount = useSelector(
        (state: any) => state.wishlist_store.count
    );

    useEffect(() => {
        const storeId: any = localStorage.getItem('location');
        dispatch(SetStoreId(storeId));
        dispatch(getCategoryData()); // to get category data
        const healthAndPharmacy = categories.find(cat => {
            return cat.slug === 'health-and-pharmacy';
        })
        dispatch(
            getAllBrandData({ attribute: 'brand', firstCategoryIdToExclude: healthAndPharmacy?._id })
        ); // for "All Brands" section but need to exclude pharma brands
        if (token) {
            dispatch(getCartItemCount()); // to get cart count on the cart badge
        }
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                (showCatDiv || showBrandDiv) &&
                divRef.current &&
                !divRef.current.contains(event.target as Node)
            ) {
                setShowCatDiv('');
                setShowBrandDiv(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showCatDiv, showBrandDiv]);

    const toggleDivVisibility = (
        setter: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
        setter((prevState) => !prevState);
    };

    const toggleDiv = (categoryId: string, slug: string) => {
        setShowCatDiv(categoryId === showCatDiv ? '' : categoryId);

        dispatch(
            SetCategoryId({
                firstCategoryId: categoryId,
            })
        );
        dispatch(ClearCurrentPage());
    };

    const brandDivTimerRef = useRef<unknown>(null);

    // 0 means no location
    const brandEnterLocationRef = useRef(0);

    const toggleBrandDiv = (locationId: number) => {
        brandEnterLocationRef.current = locationId;

        if (!showBrandDiv) {
            brandDivTimerRef.current = setTimeout(() => {
                setShowBrandDiv(true);
                if (brandDivTimerRef.current) {
                    brandDivTimerRef.current = null;
                }
            }, 250);
        }
    };

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        useState<HTMLElement | null>(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const categoryTimerRef = useRef<{ [key: string]: unknown }>({});
    const activeCategoryRef = useRef<null | string>(null);

    const handleCategoryHover = (categoryId: string) => {
        if (showCatDiv) {
            setShowCatDiv(categoryId);
            activeCategoryRef.current = categoryId;
        } else {
            categoryTimerRef.current[categoryId] = setTimeout(() => {
                setShowCatDiv(categoryId);
                activeCategoryRef.current = categoryId;
                if (categoryTimerRef.current[categoryId]) {
                    delete categoryTimerRef.current[categoryId];
                }
            }, 250);
        }
    };

    const handleCategoryLeave = (categoryId: string) => {
        categoryTimerRef.current[categoryId] &&
            clearTimeout(categoryTimerRef.current[categoryId] as string);

        setTimeout(() => {
            if (categoryId === activeCategoryRef.current) {
                setShowCatDiv('');
            }
        }, 25);
    };
    const handleBrandsLeave = (locationId: number) => {
        if (brandDivTimerRef.current) {
            clearTimeout(brandDivTimerRef.current as string);
            brandDivTimerRef.current = null;
        }
        setTimeout(() => {
            if (locationId === brandEnterLocationRef.current) {
                setShowBrandDiv(false);
                brandEnterLocationRef.current = 0;
            }
        }, 50);
    };

    const menuId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-account-menu-mobile';

    const handleSearchChange = (key: string, value: string) => {
        dispatch(SetElasticSearch({ key, value }));
        dispatch(searchData({ search: value, storeId }));
    };

    useEffect(() => {
        if (token) {
            userService.getUserProfile().then((res: any) => {
                dispatch(getWistlistData());
            });
        }
    }, [wishListDataCount]);

    const [storeOpen, setStoreOpen] = useState(false);
    const handleDrawerOpen = () => {
        setStoreOpen(true);
    };

    const handleDrawerClose = () => {
        setStoreOpen(false);
    };

    const handleWishlistClick = () => {
        if (token) {
            router.push('/wishlist');
        } else {
            setCookie('pathName', window.location.href);
            router.push('/login');
        }
    };

    const handleUserClick = () => {
        if (token) {
            router.push('/user');
        } else {
            setCookie('pathName', window.location.href);
            router.push('/login');
        }
    };

    const handleCartClick = () => {
        if (token) {
            router.push('/cart');
        } else {
            setCookie('pathName', window.location.href);
            router.push('/login');
        }
    };

    const categoryImages = {
        '658bcedee202d372af777b66': 'makeup-and-skin.jpg',
        '658bd4f28b13203c5bdb2e2c': 'health-and-pharmacy.jpg',
        '658bd52f8b13203c5bdb2e2e': 'wellness-and-personal-care.jpg',
        '658bd5498b13203c5bdb2e30': 'fragrance.png',
        '658bd55d8b13203c5bdb2e32': 'man.jpg',
        '658bd56e8b13203c5bdb2e34': 'vitamins-and-nutrition.png',
    } as { [key: string]: string };

    return (
        <>
            <LocationComponent />
            <DeviceType />
            <IPAddress />
            {/******************************* desktop  Find a Store , Track Order , Help Center top div only on desktop START Here**************** */}

            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                <div className="flex justify-end items-center h-10 mx-12 @md:hidden">
                    <div className="flex justify-between items-center w-[23%]">
                        <p
                            onClick={handleDrawerOpen}
                            className="text-[#3B3B3B] fs-18 font-normal justify-center items-center flex cursor-pointer"
                        >
                            <LocationOnIcon fontSize="small" />
                            Find a Store
                        </p>

                        <div
                            className="cursor-pointer"
                            onClick={() => router.push(`/user?tab=3`)}
                        >
                            <p className="text-[#3B3B3B] fs-18 font-normal">
                                Track Order
                            </p>
                        </div>
                        <Link href={'/contact'}>
                            <p className="text-[#3B3B3B] fs-18 font-normal">
                                Help Center
                            </p>
                        </Link>
                    </div>
                </div>
            </Box>

            {/* <PickupStoreDrawer
                Heading="Our Stores"
                isOpen={storeOpen}
                onClose={handleDrawerClose}
                RadioNdBtn={true}
            /> */}
            <StoreListDrawer
                Heading="Our Stores"
                isOpen={storeOpen}
                onClose={handleDrawerClose}
                RadioNdBtn={true}
            />

            {/******************************** desktop  Find a Store , Track Order , Help Center top div only on desktop END Here ********************************/}

            {/* desktop Top Brands ,  Members  , Inspire Me And Search Bar Second div only for desktop START Here */}

            <Box
                className="@md:hidden"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
                <AppBar
                    className="border-y border-[#3B3B3B1A]"
                    color="inherit"
                    position="static"
                    elevation={0}
                >
                    <Toolbar className="">
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                        >
                            <Link href={'/'} prefetch={false}>
                                <Image
                                    className=""
                                    width={230}
                                    height={21}
                                    src="/images/auth/white_bgLogo.png"
                                    alt="logo"
                                    sizes="(min-width: 2460px) 200px, (min-width: 900px) 181px, 200px"
                                    priority={true}
                                />
                            </Link>
                        </Typography>
                        <Box sx={{ flexGrow: 0, marginLeft: 10 }} />
                        {/* w-[40%] */}
                        <div className="flex w-[40%] h-full  justify-between items-center mt-5">
                            <div
                                className="group h-full py-4 px-3 cursor-pointer hover:outline outline-2 outline-[#e89432] rounded"
                                onMouseEnter={toggleBrandDiv.bind(null, 1)}
                                onMouseLeave={handleBrandsLeave.bind(null, 1)}
                            >
                                <p className="text-[#888484] fs-20 font-bold group-hover:text-[#FF8F2F]">
                                    Top Brands
                                </p>
                            </div>

                            {/* <div
                                className="group cursor-pointer h-full py-4 px-3 hover:outline outline-2 outline-[#e89432] rounded"
                                onClick={() => router.push(`/user?tab=0`)}
                            >
                                <p className="text-[#888484] hover:text-[#FF8F2F] fs-20 font-bold group-hover:text-[#FF8F2F]">
                                    Members
                                </p>
                            </div> */}
                            <Link
                                href={
                                    'https://www.instagram.com/aakashlifeofficial/'
                                }
                                target="_blank"
                                className="group py-4 px-3 hover:outline outline-2 outline-[#e89432] rounded"
                            >
                                <p className="text-[#888484] hover:text-[#FF8F2F] fs-20 font-bold cursor-pointer group-hover:text-[#FF8F2F]">
                                    Inspire Me
                                </p>
                            </Link>

                            <Link className="" href={'/offers'}>
                                <div
                                    className="w-[5vw] h-10 px-4 flex justify-center items-center"
                                    style={{
                                        backgroundImage:
                                            'url("/images/header/offer-bg.svg")',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                    }}
                                >
                                    <p className="text-[#fff] fs-20  font-normal text-center">
                                        Offers
                                    </p>
                                </div>
                            </Link>

                            <div
                                className="cursor-pointer"
                                onClick={() => router.push(`/user?tab=4`)}
                            >
                                <div
                                    className="w-fit h-fit px-4 py-3  "
                                    style={{
                                        backgroundImage:
                                            'url("/images/header/prescription-bg.svg")',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                >
                                    <p className="text-[#fff] fs-20 font-normal">
                                        Prescription
                                    </p>
                                </div>
                            </div>
                        </div>

                        <Box sx={{ flexGrow: 1 }} />

                        <Box
                            className=" w-[35%] absolute top-4"
                            sx={{ display: { xs: 'none', md: 'flex' } }}
                        >
                            <Stack
                                className="rounded-lg w-full"
                                spacing={1}
                                sx={{ width: '100%', padding: 0 }}
                            >
                                <SearchBar storeId={storeId} />
                            </Stack>
                        </Box>

                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            {/* options for top brands */}
            {showBrandDiv && (
                <TransluscentOnMount>
                    <div
                        onMouseEnter={toggleBrandDiv.bind(null, 2)}
                        onMouseLeave={handleBrandsLeave.bind(null, 2)}
                        className="absolute mt-0 z-10 w-[90%] flex justify-center"
                    >
                        <div className="flex flex-wrap justify-evenly bg-white rounded-xl w-[90%] pt-2 ">
                            <TopBrands
                                closeClick={() => {
                                    setShowBrandDiv(false);
                                }}
                            />
                        </div>
                    </div>
                </TransluscentOnMount>
            )}

            {/******************************** desktop Top Brands ,  Members  , Inspire Me And Search Bar Second div only on desktop END ********************************/}

            {/******************************** Desktop Top Brands Data START Here ******************************* */}

            {/******************************** Desktop Top Brands Data END Here ******************************* */}

            {/******************************** Main Category and WishList , profile , Cart Icon div for both Mobile And Desktop START Here ********************************/}

            <Box className="" sx={{ flexGrow: 1 }}>
                <AppBar
                    className="border-b border-[#3B3B3B1A] "
                    color="inherit"
                    position="static"
                    elevation={0}
                >
                    <Toolbar className="@sm:p-0 relative">
                        {/******************************** this div For Mobile Menu Icon and Logo START Here ********************************/}
                        <div className="flex justify-between items-center  ">
                            <div className="@lg:hidden @lgtab:hidden @xl:hidden @2xl:hidden ">
                                <MobileSidebar />
                            </div>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'block', sm: '' } }}
                                className="@lg:hidden @lgtab:hidden @xl:hidden @2xl:hidden"
                            >
                                <Link href={'/'} prefetch={false}>
                                    <Image
                                        width={64}
                                        height={13.5}
                                        src="/images/auth/white_bgLogo.png"
                                        alt="logo"
                                        sizes="(min-width: 380px) 148px, calc(83.33vw - 172px)"
                                        style={{
                                            height: 'auto',
                                            width: 'auto',
                                        }}
                                    />
                                </Link>
                            </Typography>
                        </div>
                        {/******************************** this div For Mobile Menu Icon and Logo ENd Here ********************************/}
                        {/******************************** this Box tag For Desktop Categories Links START Here ********************************/}
                        <Box
                            sx={{ ml: 0, display: { xs: 'none', sm: 'block' } }}
                            className="w-[65%]  flex @sm:hidden @md:hidden"
                        >
                            {categories?.map(
                                (item: any, categoryIndex: number) => (
                                    <div
                                        key={categoryIndex}
                                        onMouseEnter={() =>
                                            handleCategoryHover(item._id)
                                        }
                                        onMouseLeave={handleCategoryLeave.bind(
                                            null,
                                            item._id
                                        )}
                                    >
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                dispatch(setHeaderFilter(true));
                                                dispatch(
                                                    setIsDiscountFilter(false)
                                                );
                                                dispatch(setIsExpanded(false));
                                                toggleDiv(
                                                    item?._id,
                                                    item?.slug
                                                );
                                                handleCategoryLeave(item._id);
                                                dispatch(
                                                    setClearProductFilter()
                                                );
                                                const encodedUrl =
                                                    createEncodedURL(
                                                        'product-listing',
                                                        {
                                                            page: 1,
                                                            first: item?.slug,
                                                        }
                                                    );
                                                router.push(encodedUrl);
                                            }}
                                            className={
                                                'py-2 px-3.5 text-[#888484] hover:text-[#FF8F2F] fs-20 font-bold cursor-pointer rounded' +
                                                (showCatDiv === item._id
                                                    ? ' outline outline-2 outline-[#e89432] text-[#FF8F2F]'
                                                    : '')
                                            }
                                        >
                                            <p>{item.name}</p>
                                        </div>

                                        {showCatDiv === item._id && (
                                            <TransluscentOnMount>
                                                <div className="flex absolute z-10 subcatlist justify-center w-[100%] left-0">
                                                    <div
                                                        key={categoryIndex}
                                                        className="flex gap-x-4 h-[72vh] px-6 py-6 shadow-md bg-white rounded-2xl w-[90%] mx-auto cursor-pointer"
                                                    >
                                                        <div className="relative z-10 h-full flex-wrap flex flex-1 flex-col content-baseline items-start gap-4 basis-auto overflow-auto gap-x-2">
                                                            {item?.populatedChildren?.map(
                                                                (
                                                                    subcategory: any,
                                                                    subCatIndex: number
                                                                ) => (
                                                                    <div
                                                                        key={`${categoryIndex}-${subCatIndex}`}
                                                                        className="w-fit pr-10 cursor-pointer"
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            dispatch(
                                                                                setHeaderFilter(
                                                                                    true
                                                                                )
                                                                            );
                                                                            dispatch(
                                                                                setIsExpanded(
                                                                                    false
                                                                                )
                                                                            );
                                                                            dispatch(
                                                                                setIsDiscountFilter(
                                                                                    false
                                                                                )
                                                                            );
                                                                            dispatch(
                                                                                setClearProductFilter()
                                                                            );
                                                                            const encodedUrl =
                                                                                createEncodedURL(
                                                                                    'product-listing',
                                                                                    {
                                                                                        page: 1,
                                                                                        first: item?.slug,
                                                                                        second: subcategory?.slug,
                                                                                    }
                                                                                );
                                                                            router.push(
                                                                                encodedUrl
                                                                            );
                                                                            handleCategoryLeave(
                                                                                item._id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <h2
                                                                            key={`${categoryIndex}-${subCatIndex}"h2"`}
                                                                            className="text-lg font-bold fs-20 mb-2 capitalize"
                                                                        >
                                                                            {subcategory?.name?.toLowerCase()}
                                                                        </h2>
                                                                        <div
                                                                            key={`${categoryIndex}-${subCatIndex}`}
                                                                            className="fs-18 mb-3 h-fit"
                                                                        >
                                                                            {subcategory?.populatedChildren?.map(
                                                                                (
                                                                                    subSubCategory: any,
                                                                                    subSubCatIndex: number
                                                                                ) => (
                                                                                    <div
                                                                                        key={`${categoryIndex}-${subCatIndex}-${subSubCatIndex}`}
                                                                                        className="text-xs leading-none mb-3 h-fit cursor-pointer capitalize"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            dispatch(
                                                                                                setHeaderFilter(
                                                                                                    true
                                                                                                )
                                                                                            );
                                                                                            dispatch(
                                                                                                setIsExpanded(
                                                                                                    false
                                                                                                )
                                                                                            );
                                                                                            dispatch(
                                                                                                setIsDiscountFilter(
                                                                                                    false
                                                                                                )
                                                                                            );
                                                                                            e.stopPropagation();
                                                                                            e.preventDefault();
                                                                                            const encodedUrl =
                                                                                                createEncodedURL(
                                                                                                    'product-listing',
                                                                                                    {
                                                                                                        page: 1,
                                                                                                        first: item?.slug,
                                                                                                        second: subcategory?.slug,
                                                                                                        third: subSubCategory?.slug,
                                                                                                    }
                                                                                                );
                                                                                            router.push(
                                                                                                encodedUrl
                                                                                            );
                                                                                            handleCategoryLeave(
                                                                                                item._id
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {subSubCategory.name?.toLowerCase()}
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                        {!!item
                                                            ?.populatedChildren
                                                            ?.length &&
                                                            categoryImages[
                                                                showCatDiv
                                                            ] && (
                                                                <div
                                                                    className="shrink-0 grow-0 ml-auto w-[350px] h-[350px] bg-cover rounded-lg overflow-hidden"
                                                                    style={{
                                                                        backgroundImage: `url(/images/${categoryImages[showCatDiv]})`,
                                                                        backgroundPosition:
                                                                            'center center',
                                                                    }}
                                                                ></div>
                                                            )}
                                                    </div>
                                                </div>
                                            </TransluscentOnMount>
                                        )}
                                    </div>
                                )
                            )}
                        </Box>

                        {/******************************** this Box tag For Desktop Categories Links ENd Here   ********************************/}
                        <Box sx={{ flexGrow: 1 }} />
                        <Box
                            className="flex gap-5 @sm:gap-0 @sm:mr-2 mr-5"
                            sx={{ display: { xs: '', md: 'block' } }}
                        >
                            {/* wishlist */}
                            <IconButton
                                size="medium"
                                aria-label="show 4 new Wishlist"
                                color="inherit"
                                sx={{ display: { xs: 'block', md: 'block' } }}
                            >
                                <Badge
                                    badgeContent={token ? wishListDataCount : 0}
                                    color="error"
                                >
                                    <div
                                        // href={token ? '/wishlist' : '/login'}
                                        // prefetch={false}
                                        onClick={handleWishlistClick}
                                    >
                                        {' '}
                                        <FavoriteBorderIcon
                                            sx={{
                                                fontSize: 30,
                                                color: '#3B3B3B',
                                            }}
                                        />{' '}
                                    </div>
                                </Badge>
                            </IconButton>

                            <IconButton
                                size="medium"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <div
                                    // href={token ? '/user' : '/login'}
                                    // prefetch={false}
                                    onClick={handleUserClick}
                                >
                                    <AccountCircle
                                        sx={{ fontSize: 30, color: '#3B3B3B' }}
                                    />
                                </div>
                            </IconButton>
                            <IconButton
                                className="@sm:ml-1"
                                size="medium"
                                aria-label="show 17 new notifications"
                                color="inherit"
                            >
                                <Badge
                                    badgeContent={token ? cartCount : 0}
                                    color="error"
                                >
                                    <div
                                        // href={token ? '/cart' : '/login'}
                                        // prefetch={false}
                                        onClick={handleCartClick}
                                    >
                                        {' '}
                                        <ShoppingCartIcon
                                            sx={{
                                                fontSize: 30,
                                                color: '#3B3B3B',
                                            }}
                                        />{' '}
                                    </div>
                                </Badge>
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>

                <div className="border-b border-[#3B3B3B1A] px-3 py-2 @lg:hidden @lgtab:hidden @xl:hidden @2xl:hidden">
                    <SearchBar storeId={storeId} />
                </div>

                {/*on click category show sub category this Box tag For Desktop sub Categories Links  */}
                {/* {showCatDiv && (
          <div className="flex absolute z-10  justify-center w-[90%]">
            <div ref={divRef} className="h-[72vh] bg-white rounded-2xl w-[80%] mx-auto overflow-auto">
              <div className="z-10  h-[100%]  flex-wrap flex flex-1 flex-col content-baseline items-center">
                {categories.map((category, categoryIndex) => (
                  <div key={categoryIndex} className="mx-[3%]">
                    <h2 className="text-lg font-bold fs-20 mb-2 mt-3">{category}</h2>
                    {subcategories[category] ? (
                      subcategories[category].map((subcategory, subcategoryIndex) => (
                        <div key={subcategoryIndex} className="fs-18 mb-5 h-fit">
                          {subcategory}
                        </div>
                      ))
                    ) : (
                      <p>No subcategories found</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )} */}
            </Box>

            {/******************************** Main Category and WishList , profile , Cart Icon div for both Mobile And Desktop START Here ********************************/}
        </>
    );
}
