'use client';
import Link from 'next/link';
import React, { useState } from 'react';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useAppSelector } from '@/store/store';

const Footer = () => {
    const [hovered, setHovered] = useState<number | null>();
    const router = useRouter();
    const { stores } = useAppSelector((state) => state.store_store) || {};

    const handleClickContact = () => {
        router.push('/contact');
    };

    const handleClickAbout = () => {
        router.push('/about');
    };

    const handleMouseEnter = (id: number) => {
        setHovered(id);
    };

    const handleMouseLeave = () => {
        setHovered(null);
    };
    return (
        <div className="w-full bg-[#000000] bg-opacity-[6%] pt-[3%] pb-4">
            {/* deskhrefp footer  */}
            <div className="@sm:hidden">
                <div className="">
                    <div className="flex flex-row justify-between mb-4 px-14 @md:px-7">
                        {/* logo  */}
                        <div className="flex flex-col w-[50%] @md:w-[35%]">
                            <div>
                                <Image
                                    width={200}
                                    height={200}
                                    src="/images/auth/white_bgLogo.png"
                                    className="  @sm:h-6"
                                    alt="Aakash Life"
                                />
                            </div>
                            <div className="text-[#7F8386] fs-20 fw-400 w-[85%] mt-6 leading-tight">
                                Stay in touch and keep up to date with all the
                                activities, news and events.
                            </div>
                            <div className=" w-[40%] @md:w-[30%] mt-8">
                                <div className="text-[#7F8386] fs-18 font-bold">
                                    FIND US ON
                                </div>
                                <div className="flex flex-row mt-3 gap-4 cursor-pointer">
                                    {/* Facebook  */}
                                    <Link
                                        href={
                                            'https://www.facebook.com/people/Aakashlifeofficial/61551894700888/'
                                        }
                                    >
                                        <div
                                            onMouseEnter={() =>
                                                handleMouseEnter(1)
                                            }
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            {hovered === 1 ? (
                                                <FacebookRoundedIcon
                                                    color="primary"
                                                    sx={{ fontSize: 35 }}
                                                />
                                            ) : (
                                                <FacebookRoundedIcon
                                                    color="inherit"
                                                    sx={{ fontSize: 35 }}
                                                />
                                            )}
                                        </div>
                                    </Link>
                                    {/* Linkedin  */}
                                    <Link
                                        href={
                                            'https://www.Linkedin.com/company/aakash-life/posts/?feedView=all'
                                        }
                                    >
                                        <div
                                            onMouseEnter={() =>
                                                handleMouseEnter(2)
                                            }
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            {hovered === 2 ? (
                                                <LinkedInIcon
                                                    // color="primary"
                                                    className="rounded-full"
                                                    sx={{
                                                        fontSize: 35,
                                                        color: '#0A66C2',
                                                    }}
                                                />
                                            ) : (
                                                <LinkedInIcon
                                                    color="inherit"
                                                    className="rounded-full"
                                                    sx={{ fontSize: 35 }}
                                                />
                                            )}
                                        </div>
                                    </Link>
                                    {/* twitter  */}
                                    <Link
                                        href={'https://twitter.com/aakash_life'}
                                    >
                                        <div
                                            onMouseEnter={() =>
                                                handleMouseEnter(3)
                                            }
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            {hovered === 3 ? (
                                                <TwitterIcon
                                                    // color="primary"
                                                    className="rounded-full"
                                                    sx={{
                                                        fontSize: 35,
                                                        color: '#00acee',
                                                    }}
                                                />
                                            ) : (
                                                <TwitterIcon
                                                    color="inherit"
                                                    className="rounded-full"
                                                    sx={{ fontSize: 35 }}
                                                />
                                            )}
                                        </div>
                                    </Link>
                                    {/* instagram  */}
                                    <Link
                                        href={
                                            'https://www.instagram.com/aakashlifeofficial/?igshid=OGQ5ZDc2ODk2ZA%3D%3D'
                                        }
                                    >
                                        <div
                                            onMouseEnter={() =>
                                                handleMouseEnter(4)
                                            }
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            {hovered === 4 ? (
                                                <InstagramIcon
                                                    color="primary"
                                                    className="rounded-full"
                                                    sx={{
                                                        fontSize: 35,
                                                        color: '#E4405F',
                                                    }}
                                                />
                                            ) : (
                                                <InstagramIcon
                                                    color="inherit"
                                                    className="rounded-full"
                                                    sx={{ fontSize: 35 }}
                                                />
                                            )}
                                        </div>
                                    </Link>
                                    {/* youtube  */}
                                    <Link
                                        href={
                                            'https://www.youtube.com/channel/UCV5Bptaec3AF4TpMhk1x5-Q'
                                        }
                                    >
                                        <div
                                            onMouseEnter={() =>
                                                handleMouseEnter(5)
                                            }
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            {hovered === 5 ? (
                                                <YouTubeIcon
                                                    color="error"
                                                    className="rounded-full"
                                                    sx={{ fontSize: 35 }}
                                                />
                                            ) : (
                                                <YouTubeIcon
                                                    color="inherit"
                                                    className="rounded-full"
                                                    sx={{ fontSize: 35 }}
                                                />
                                            )}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* Company  */}
                        <div className=" w-[20%] @md:w-[30%] pt-[2%]">
                            <div className="text-[#7F8386] fs-20 font-bold">
                                COMPANY
                            </div>

                            <Link href={'/about'}>
                                <div
                                    className="text-[#7F8386] fs-18 fw-400  mt-5 cursor-pointer"
                                    onClick={handleClickAbout}
                                >
                                    About Aakash Life
                                </div>
                            </Link>

                            <Link href={'/contact'}>
                                <div
                                    className="text-[#7F8386] fs-18 fw-400  mt-3 cursor-pointer"
                                    onClick={handleClickContact}
                                >
                                    Contact
                                </div>
                            </Link>
                            {/* <div className="text-[#7F8386] fs-18 fw-400  mt-3 cursor-pointer" onClick={handleClickContact}>
                                Blogs
                            </div>
                            <div className="text-[#7F8386] fs-18 fw-400  mt-3 cursor-pointer" onClick={handleClickContact}>
                                FAQ
                            </div>
                            <div className="text-[#7F8386] fs-18 fw-400  mt-3 cursor-pointer" onClick={handleClickContact}>
                                Doctor Appointment
                            </div> */}
                        </div>

                        {/* Our Policies  */}
                        <div className=" w-[35%] @md:w-[25%] pt-[2%]">
                            <div className="text-[#7F8386] fs-20 font-bold ">
                                OUR POLICIES
                            </div>

                            <Link href="/terms-conditions">
                                <div className="text-[#7F8386] fs-18 fw-400  mt-5 cursor-pointer">
                                    Terms and Conditions
                                </div>
                            </Link>

                            <Link href="privacy-policy">
                                <div className="text-[#7F8386] fs-18 fw-400  mt-3 cursor-pointer">
                                    Privacy Policy
                                </div>
                            </Link>
                            <Link href="/feePayment-policy">
                                <div className="text-[#7F8386] fs-18 fw-400  mt-3 cursor-pointer">
                                    Fees and Payment Policy
                                </div>
                            </Link>
                            <Link href="/shipping-delivery-policy">
                                <div className="text-[#7F8386] fs-18 fw-400  mt-3 cursor-pointer">
                                    Shipping and Delivery Policy
                                </div>
                            </Link>
                            <Link href="/return-refund-policy">
                                <div className="text-[#7F8386] fs-18 fw-400  mt-3 cursor-pointer">
                                    Return, Refund and Cancellation Policy
                                </div>
                            </Link>
                            {/* <Link href="/loyality-policy"> <div className="text-[#7F8386] fs-18 fw-400  mt-3 cursor-pointer" >
                            Loyality Policy
                            </div> </Link> */}
                        </div>

                        {/* address  */}
                        <div className=" w-[45%] @md:w-[35%] pt-[2%]">
                            <div className="text-[#7F8386] fs-20 font-bold">
                                CONTACT US
                            </div>
                            <div className="flex flex-row items-center mt-5 mb-3">
                                {/* <Image
                                alt=""
                                width={100}
                                height={100}
                                src="/public/footer-images/"
                                className="h-5 w-5 @md:w-4 @md:h-4"
                                style={{ objectFit: "contain" }}
                                /> */}
                                <LocalPhoneOutlinedIcon
                                    className="fs-22 fw-400"
                                    sx={{ color: '#FF8F2F' }}
                                />
                                <div className="fs-20 fw-400 text-[#7F8386] ml-4 ">
                                    <a href="tel:+91 8800050088">
                                        +91 8800050088
                                    </a>
                                </div>
                            </div>

                            <div className="flex flex-row items-center mt-3">
                                <MailOutlineOutlinedIcon
                                    className="fs-22 fw-400"
                                    sx={{ color: '#FF8F2F' }}
                                />

                                <div className="fs-20 fw-400 text-[#7F8386] ml-4">
                                    <a href="mailhref:info@aakashlife.in">
                                        info@aakashlife.in
                                    </a>
                                </div>
                            </div>

                            {/* <div className="text-[#7F8386] fs-16 font-bold mt-4 mb-2">
                                ADDRESS
                            </div>
                            <div className="fs-18 font-bold text-[#7F8386] mt-2">
                                Aakash Medicines Private Limited
                            </div>
                            <div className="flex flex-row items-center mt-2 mb-3">
                                <LocationOnOutlinedIcon
                                    className="text-16 fw-400"
                                    sx={{ color: '#FF8F2F' }}
                                />
                                <div className="fs-18 fw-400 text-[#7F8386] mt-1 w-[85%] ml-3">
                                    704, 7th Floor, Time Tower, Sector-28,
                                    Mehrauli-Gurgaon Road, Gurugram, Haryana,
                                    122002
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="mt-6 px-14 fs-18 font-bold text-[#7F8386]">
                        <p>VISIT US</p>
                    </div>
                    <div className="flex gap-x-[300px] px-14 @md:px-7">
                        {stores
                            .filter((store) => store.type !== 'warehouse')
                            .map((store) => (
                                <div key={store._id}>
                                    <p className="fs-18 font-bold text-[#7F8386] mt-2">
                                        {store.name}
                                    </p>
                                    <div className="flex flex-row items-center mt-2 mb-3">
                                        <p className="fs-18 fw-400 text-[#7F8386] mt-1 max-w-36 leading-snug">
                                            {store.address}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        {/* <div>
                            <p className="fs-18 font-bold text-[#7F8386] mt-2">
                                RAJOURI GARDEN
                            </p>
                            <div className="flex flex-row items-center mt-2 mb-3">
                                <p className="fs-18 fw-400 text-[#7F8386] mt-1 max-w-36 leading-snug">
                                    NO. 1, BLOCK-E, RAJOURI GARDEN, NEW DELHI -
                                    27
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="fs-18 font-bold text-[#7F8386] mt-2">
                                EAST OF KAILASH
                            </p>
                            <div className="flex flex-row items-center mt-2 mb-3">
                                <p className="fs-18 fw-400 text-[#7F8386] mt-1 max-w-36 leading-snug">
                                    NO. 9, BLOCK-F, EAST OF KAILASH, NEW DELHI -
                                    65
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="fs-18 font-bold text-[#7F8386] mt-2">
                                MODEL TOWN II
                            </p>
                            <div className="flex flex-row items-center mt-2 mb-3">
                                <p className="fs-18 fw-400 text-[#7F8386] mt-1 max-w-36 leading-snug">
                                    NO. 7, B-1, MODEL TOWN II, NEW DELHI - 09
                                </p>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="">
                    <div className="text-[#0B1219] fs-14 ps-14 @sm:ps-2 mt-12 fw-400">
                        WE ACCEPT
                    </div>
                    <div className="flex flex-row justify-center flex-wrap md:justify-start gap-[3%] mt-4 ps-14 @sm:ps-2 mb-6 w-[100%] ">
                        <Image
                            width={100}
                            height={100}
                            className="aspect-[.3/.07] object-contain "
                            src="/footer-images/google-pay.svg.png"
                            alt="googlepay"
                        />
                        <Image
                            width={100}
                            height={100}
                            className="aspect-[.3/.07] object-contain"
                            src="/footer-images/phonepe.svg.png"
                            alt="phonepay"
                        />
                        <Image
                            width={100}
                            height={100}
                            className="aspect-[.3/.07] object-contain"
                            src="/footer-images/paytm.svg.png"
                            alt="paytm"
                        />
                        {/* <Image width={100} height={100} className="aspect-[.3/.07] object-contain" src="/footer-images/simpl.svg.png" alt="simple" /> */}
                        <Image
                            width={100}
                            height={100}
                            className="aspect-[.3/.07] object-contain"
                            src="/footer-images/rupay.svg.png"
                            alt="rupay"
                        />
                        <Image
                            width={100}
                            height={100}
                            className="aspect-[.3/.07] object-contain"
                            src="/footer-images/visa.svg.png"
                            alt="visa"
                        />
                        <Image
                            width={100}
                            height={100}
                            className="aspect-[.3/.07] object-contain"
                            src="/footer-images/mastercard.svg.png"
                            alt="master"
                        />
                    </div>
                </div>
                <div className="text-center border-t border-t-[#000000] border-opacity-[10%] mt-4 pt-4 mb-4 fs-20 fw-400 text-black">
                    Copyright© 2024. All Rights Reserve
                </div>
            </div>

            {/* Mobile footer */}
            <div className="block  @lg:hidden @md:hidden @xl:hidden @2xl:hidden ">
                <div className="px-4">
                    <div className="mb-4">
                        <Image
                            width={150}
                            height={150}
                            src="/images/auth/white_bgLogo.png"
                            className=""
                            alt="Aakash Life"
                        />
                        <p className="text-[#7F8386] fs-18 mt-4">
                            Stay in touch and keep up to date with all the
                            activities, news and events.
                        </p>
                    </div>
                    {/* Find us on social media */}
                    <div className="text-[#7F8386] text-xs">
                        <div className="flex flex-row mt-3 cursor-pointer">
                            {/* Links to social media */}
                            {/* Facebook */}
                            <Link href="https://www.facebook.com/people/Aakashlifeofficial/61551894700888/">
                                <div
                                    onMouseEnter={() => handleMouseEnter(1)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <FacebookRoundedIcon
                                        color="primary"
                                        sx={{ fontSize: 35 }}
                                    />
                                </div>
                            </Link>
                            {/* Linkedin */}
                            <Link href="https://www.Linkedin.com/company/aakash-life/posts/?feedView=all">
                                <div
                                    onMouseEnter={() => handleMouseEnter(2)}
                                    onMouseLeave={handleMouseLeave}
                                    className="ml-2"
                                >
                                    <LinkedInIcon
                                        // color="primary"
                                        className="rounded-full"
                                        sx={{ fontSize: 35, color: '#0A66C2' }}
                                    />
                                </div>
                            </Link>
                            {/* Twitter */}
                            <Link href="https://twitter.com/aakash_life">
                                <div
                                    onMouseEnter={() => handleMouseEnter(3)}
                                    onMouseLeave={handleMouseLeave}
                                    className="ml-2"
                                >
                                    <TwitterIcon
                                        // color="primary"
                                        className="rounded-full"
                                        sx={{ fontSize: 35, color: '#00acee' }}
                                    />
                                </div>
                            </Link>
                            {/* Instagram */}
                            <Link href="https://www.instagram.com/aakashlifeofficial/?igshid=OGQ5ZDc2ODk2ZA%3D%3D">
                                <div
                                    onMouseEnter={() => handleMouseEnter(4)}
                                    onMouseLeave={handleMouseLeave}
                                    className="ml-2"
                                >
                                    <InstagramIcon
                                        color="primary"
                                        className="rounded-full"
                                        sx={{ fontSize: 35, color: '#E4405F' }}
                                    />
                                </div>
                            </Link>
                            {/* Youtube */}
                            <Link href="https://www.youtube.com/channel/UCV5Bptaec3AF4TpMhk1x5-Q">
                                <div
                                    onMouseEnter={() => handleMouseEnter(5)}
                                    onMouseLeave={handleMouseLeave}
                                    className="ml-2"
                                >
                                    <YouTubeIcon
                                        color="error"
                                        className="rounded-full"
                                        sx={{ fontSize: 35 }}
                                    />
                                </div>
                            </Link>
                        </div>
                    </div>
                    {/* Company section */}
                    <div className="text-[#7F8386] text-xs mt-8">
                        <div className="text-[#7F8386] fs-16 font-bold">
                            COMPANY
                        </div>
                        <Link href={'/about'}>
                            <div
                                onClick={handleClickAbout}
                                className="mt-3 cursor-pointer"
                            >
                                About Aakash Life
                            </div>
                        </Link>
                        <Link href={'/contact'}>
                            <div
                                onClick={handleClickContact}
                                className="mt-3 cursor-pointer"
                            >
                                Contact
                            </div>
                        </Link>
                        {/* <div onClick={handleClickContact} className="mt-3 cursor-pointer">
              Blogs
            </div>
            <div onClick={handleClickContact} className="mt-3 cursor-pointer">
              FAQ
            </div>
            <div onClick={handleClickContact} className="mt-3 cursor-pointer">
              Doctor Appointment
            </div> */}
                    </div>
                    {/* Our Policies section */}
                    <div className="text-[#7F8386] text-xs mt-5">
                        <div className="text-[#7F8386] fs-16 font-bold ">
                            OUR POLICIES
                        </div>
                        <Link href="/terms-conditions">
                            <div
                                onClick={handleClickAbout}
                                className="mt-3 cursor-pointer"
                            >
                                Terms and Conditions
                            </div>
                        </Link>
                        <Link href="/privacy-policy">
                            <div
                                onClick={handleClickContact}
                                className="mt-3 cursor-pointer"
                            >
                                Privacy Policy
                            </div>
                        </Link>
                        <Link href="/feePayment-policy">
                            <div
                                onClick={handleClickContact}
                                className="mt-3 cursor-pointer"
                            >
                                Fees and Payment Policy
                            </div>
                        </Link>
                        <Link href="/shipping-delivery-policy">
                            <div
                                onClick={handleClickContact}
                                className="mt-3 cursor-pointer"
                            >
                                Shipping and Delivery Policy
                            </div>
                        </Link>
                        <Link href="/return-refund-policy">
                            <div
                                onClick={handleClickContact}
                                className="mt-3 cursor-pointer"
                            >
                                Return, Refund and Cancellation Policy
                            </div>
                        </Link>
                        {/* <div onClick={handleClickContact} className="mt-3 cursor-pointer">
              Help Center
            </div> */}
                    </div>
                    {/* Address section */}
                    <div className="text-[#7F8386] text-xs mt-5">
                        <div className="text-[#7F8386] fs-16 font-bold mb-3">
                            CONTACT US
                        </div>
                        <div className="flex flex-row items-center mb-3">
                            <LocalPhoneOutlinedIcon
                                className="fs-22 fw-400"
                                sx={{ color: '#FF8F2F' }}
                            />
                            <div className="ml-2">
                                <a href="tel:+91 8800050088 fs-20">
                                    +91 8800050088
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <MailOutlineOutlinedIcon
                                className="fs-22 fw-400"
                                sx={{ color: '#FF8F2F' }}
                            />
                            <div className="ml-2">
                                <a href="mailto:info@aakashlife.in">
                                    info@aakashlife.in
                                </a>
                            </div>
                        </div>
                        <div className="mt-5 mb-3 fs-16 font-bold">
                            VISIT US
                        </div>
                        {/* <div className=" text-xs font-bold">
                            Aakash Medicines Private Limited
                        </div>
                        <div className="flex flex-row items-start gap-2 mt-2 w-[60%]">
                            <LocationOnOutlinedIcon
                                className="text-14 fw-400"
                                sx={{ color: '#FF8F2F' }}
                            />
                            <div className=" text-xs ">
                                704, 7th Floor, Time Tower, Sector-28,
                                Mehrauli-Gurgaon Road, Gurugram, Haryana, 122002{' '}
                            </div>
                        </div> */}
                        <div>
                            <p className="text-xs font-bold text-[#7F8386] mt-2">
                                RAJOURI GARDEN
                            </p>
                            <div className="flex flex-row items-center mt-1 mb-3">
                                <p className="text-xs fw-400 text-[#7F8386] mt-1 max-w-36 leading-snug">
                                    NO. 1, BLOCK-E, RAJOURI GARDEN, NEW DELHI -
                                    27
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="text-xs font-bold text-[#7F8386] mt-2">
                                EAST OF KAILASH
                            </p>
                            <div className="flex flex-row items-center mt-1 mb-3">
                                <p className="text-xs fw-400 text-[#7F8386] mt-1 max-w-36 leading-snug">
                                    NO. 9, BLOCK-F, EAST OF KAILASH, NEW DELHI -
                                    65
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="text-xs font-bold text-[#7F8386] mt-2">
                                MODEL TOWN II
                            </p>
                            <div className="flex flex-row items-center mt-1 mb-3">
                                <p className="text-xs fw-400 text-[#7F8386] mt-1 max-w-36 leading-snug">
                                    NO. 7, B-1, MODEL TOWN II, NEW DELHI - 09
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Payment options */}
                    <div className="text-center mt-8 text-[#3b3b3b] text-xs">
                        WE ACCEPT
                    </div>
                    <div className="flex flex-wrap gap-2 justify-center mt-4 ps-14 @sm:ps-2">
                        <Image
                            width={100}
                            height={100}
                            className="w-16 h-5  object-contain "
                            src="/footer-images/google-pay.svg.png"
                            alt="googlepay"
                        />
                        <Image
                            width={100}
                            height={100}
                            className="w-16 h-5  object-contain"
                            src="/footer-images/phonepe.svg.png"
                            alt="phonepay"
                        />
                        <Image
                            width={100}
                            height={100}
                            className="w-16 h-5 object-contain"
                            src="/footer-images/paytm.svg.png"
                            alt="paytm"
                        />
                        {/* <Image width={100} height={100} className="w-16 h-5  object-contain" src="/footer-images/simpl.svg.png" alt="simple" /> */}
                        <Image
                            width={100}
                            height={100}
                            className="w-16 h-5  object-contain"
                            src="/footer-images/rupay.svg.png"
                            alt="rupay"
                        />
                        <Image
                            width={100}
                            height={100}
                            className="w-16 h-5  object-contain"
                            src="/footer-images/visa.svg.png"
                            alt="visa"
                        />
                        <Image
                            width={100}
                            height={100}
                            className="w-16 h-5  object-contain"
                            src="/footer-images/mastercard.svg.png"
                            alt="master"
                        />
                    </div>
                </div>

                <div className="text-center mt-6 mb-6 fs-16 fw-400 text-black border-t pt-4 border-t-[#000000] border-opacity-[10%]">
                    Copyright© 2024. All Rights Reserve
                </div>
            </div>
        </div>
    );
};

export default Footer;
