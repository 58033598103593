import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { Tab } from '@mui/base/Tab';

import MenuIcon from '@mui/icons-material/Menu';
import { Close } from '@mui/icons-material';
import CategoriesComp from './categories-comp';
import BrandComponent from './brand-comp';
import CustomButton from '@/components/button';

type Anchor = 'left';
interface MakeupBrandItem {
    id: number;
    label: string;
    comp: any;
}

export default function MobileSidebar() {
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setState({ ...state, [anchor]: open });
        };

    const MakeupBrand: MakeupBrandItem[] = [
        {
            id: 1,
            label: 'Categories',
            comp: <CategoriesComp toggleDrawer={toggleDrawer} />,
        },
        {
            id: 2,
            label: 'Top Brands',
            comp: <BrandComponent toggleDrawer={toggleDrawer} />,
        },
    ];

    const list = (anchor: Anchor) => (
        <Box sx={{ width: 380 }} role="presentation">
            <div className="flex justify-end">
                <div
                    onClick={toggleDrawer(anchor, false)}
                    className=" w-10 h-10 flex justify-center items-center"
                >
                    <Close sx={{ color: '#fff' }} />
                </div>
            </div>
            <>
                <Tabs className="w-full" defaultValue={1}>
                    <TabsList className="mb-4 rounded-xl flex ">
                        {MakeupBrand.map((brand) => (
                            <Tab
                                key={brand.id}
                                slotProps={{
                                    root: ({ selected, disabled }) => ({
                                        className: ` ${selected ? 'text-[#FF8F2F] font-bold border-b-[1px]  border-[#FF8F2F]' : 'text-white font-bold border-b-[1px]  border-[#fff]'} ${
                                            disabled
                                                ? 'cursor-not-allowed opacity-50'
                                                : 'cursor-pointer '
                                        } w-full py-4  border-0  fs-16`,
                                    }),
                                }}
                                value={brand.id}
                            >
                                {brand.label}
                            </Tab>
                        ))}
                    </TabsList>
                    {MakeupBrand.map((brand) => (
                        <TabPanel
                            key={brand.id}
                            className="w-full text-white"
                            value={brand.id}
                        >
                            {brand.comp}
                        </TabPanel>
                    ))}
                </Tabs>
            </>
        </Box>
    );

    return (
        <div>
            <Button onClick={toggleDrawer('left', true)}>
                <MenuIcon
                    color="inherit"
                    sx={{ fontSize: 40, fontWeight: 'bold', color: '#3B3B3B' }}
                />
            </Button>
            <Drawer
                PaperProps={{
                    style: {
                        backgroundColor: '#6E2A61', // Set your desired background color
                    },
                }}
                anchor="left"
                open={state.left}
                onClose={toggleDrawer('left', false)}
            >
                {list('left')}
            </Drawer>
        </div>
    );
}
