import { useEffect } from "react";

type TransluscentOnMountProps = {
    children: React.ReactNode
}

export default function TransluscentOnMount(props: TransluscentOnMountProps) {
    const { children } = props;

useEffect(() => {
    document.body.style.overflow = 'hidden';
    
    return () => {
        document.body.style.overflow = 'auto';
    }
} , [])

    return (
        <>
            <div className="fixed inset-0 bg-black bg-opacity-20 pointer-events-none"></div>
            {children}
        </>
    );
}
