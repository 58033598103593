import React from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '@/store/store';
import { Store, setStoreIdForPickup } from '@/store/slices/checkout-slice';

import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import CustomButton from '../button';

interface CheckAvailabilityDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    Heading?: string;
    pickUpTime?: string;
    RadioNdBtn?: boolean;
    howMany?: number;
    cartId: string | null;
}

const PickupStoreDrawer: React.FC<CheckAvailabilityDrawerProps> = ({
    isOpen,
    onClose,
    Heading,
    howMany,
    pickUpTime,
    RadioNdBtn,
    cartId,
}) => {
    const { stores: storesData, selectedStoreIdForPickup } = useAppSelector(
        (state) => state.checkout_store
    );

    // const stores = [
    //     {
    //         id: 1,
    //         distance: '',
    //         pickupTime: pickUpTime,
    //         name: 'Rajouri Garden',
    //         address:
    //             'No. 1, E Block, Rajouri Garden, West Delhi, Delhi - 110027',
    //         phone: '+91-966-798-8008',
    //     },
    //     {
    //         id: 2,
    //         distance: '',
    //         pickupTime: pickUpTime,
    //         name: 'East of Kailash',
    //         address: 'F9, East of Kailash, Delhi 110065',
    //         phone: '+91-011-408-7077',
    //     },
    // ];

    return (
        <Drawer
            id="availability-modal"
            anchor="right"
            open={isOpen}
            onClose={onClose}
        >
            <div className="flex flex-col px-6 pb-4 h-full">
                <div className="flex justify-between items-center py-5">
                    <p className="font-bold text-lg text-[#3B3B3B]">
                        {Heading}
                    </p>
                    <CloseIcon onClick={onClose} />
                </div>

                <div className="basis-0 grow overflow-auto">
                    {storesData.default && (
                        <div>
                            <p className="text-base font-semibold">
                                Nearest store you can pick your order from:
                            </p>
                            <StoreCard
                                className="mt-4"
                                storeItem={storesData.default}
                                selectedStoreId={selectedStoreIdForPickup}
                            />
                        </div>
                    )}
                    {!!storesData.others.length && (
                        <>
                            <p className="mt-6 text-base font-semibold">
                                Other store you can pick your order from:
                            </p>
                            {storesData.others.map((storeItem) => (
                                <StoreCard
                                    key={storeItem._id}
                                    className="mt-4"
                                    storeItem={storeItem}
                                    selectedStoreId={selectedStoreIdForPickup}
                                />
                            ))}
                        </>
                    )}
                    {/* {!!storesData.storeWithDifferentBatch.length && (
                        <>
                            <p className="mt-6 text-base font-semibold">
                                Stores that have other batches of the same product (Price may vary in these stores):
                            </p>
                            {storesData.storeWithDifferentBatch.map(
                                (storeItem) => (
                                    <StoreCard
                                        key={storeItem._id}
                                        className="mt-4"
                                        storeItem={storeItem}
                                        selectedStoreId={
                                            selectedStoreIdForPickup
                                        }
                                    />
                                )
                            )}
                        </>
                    )} */}
                </div>
                <div
                    className={`${
                        RadioNdBtn
                            ? 'hidden'
                            : 'flex justify-center items-center w-full mt-3'
                    }`}
                >
                    <div onClick={onClose} className="w-[50%] ">
                        <CustomButton>Confirm</CustomButton>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

type StoreCardProps = {
    storeItem: Store;
    RadioNdBtn?: boolean;
    selectedStoreId: string | null;
    className?: string;
};

export const StoreCard = (props: StoreCardProps) => {
    const { storeItem, RadioNdBtn, selectedStoreId, className } = props;
    const dispatch = useDispatch();

    const handleChange = (storeId: string) => {
        dispatch(setStoreIdForPickup({ storeId }));
    };

    return (
        <div
            className={`@md:ps-2 justify-center items-center flex ${className}`}
            key={storeItem._id}
        >
            <div className="flex items-center mb-5 gap-3 w-full">
                <div className={`${RadioNdBtn ? 'hidden' : ''}`}>
                    <Radio
                        sx={{
                            color: '#FF8F2F',
                            '&.Mui-checked': {
                                color: '#FF8F2F',
                            },
                        }}
                        checked={selectedStoreId === storeItem._id}
                        onChange={() => handleChange(storeItem._id)}
                        value={storeItem._id.toString()}
                        name="radio-buttons"
                        inputProps={{
                            'aria-label': `Store ${storeItem._id}`,
                        }}
                    />
                </div>
                <Card
                    className={`${
                        RadioNdBtn
                            ? 'lg:w-[100%] @sm:w-full'
                            : 'lg:w-[80%] @md:w-[75%] @sm:w-[75%]'
                    }`}
                >
                    <CardContent className="flex flex-col gap-3">
                        {/* <p className="fs-14 font-medium">
                                    {storeItem.distance}
                                </p> */}
                        {/* <p className="fs-14 font-medium text-[#A6C363]">
                                    {storeItem.pickupTime}
                                </p> */}
                        <p className="fs-14 font-bold">{storeItem.name}</p>
                        {storeItem.address && <p className="fs-14 font-normal">{storeItem.address}</p>}
                        {storeItem.mobile && <p className="fs-14 py-2 font-normal">
                            {storeItem.mobile}
                        </p>}
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default PickupStoreDrawer;
