import { useState, useEffect } from "react";

export const errorFlashDuration = 700;

export default function useFlashOnError(error) {
    const [showBorder, setShowBorder] = useState(false);

    useEffect(() => {
        if (error) {
            setShowBorder(true);
            setTimeout(setShowBorder, errorFlashDuration, false);
        }
    }, [error]);

    return showBorder;
}
