import React from 'react';

import { useAppSelector } from '@/store/store';

import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';

import { StoreCard } from '../cart/pickup-store-drawer';

interface StoreListDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    Heading?: string;
    RadioNdBtn?: boolean;
}

const StoreListDrawer: React.FC<StoreListDrawerProps> = ({
    isOpen,
    onClose,
    Heading,
    RadioNdBtn,
}) => {
    const {  selectedStoreIdForPickup } = useAppSelector(
        (state) => state.checkout_store
    );
    const { stores  } = useAppSelector(state => state.store_store);

    return (
        <Drawer
            id="availability-modal"
            anchor="right"
            open={isOpen}
            onClose={onClose}
        >
            <div className="flex flex-col px-6 pb-4 h-full border-2 border-blue-300">
                <div className="flex justify-between items-center py-5">
                    <p className="font-bold text-lg text-[#3B3B3B]">
                        {Heading}
                    </p>
                    <CloseIcon onClick={onClose} />
                </div>

                <div className="basis-0 grow overflow-auto">
                    {!!stores.length && (
                        <>
                            {stores.filter(store => {
                                return store?.type !== 'warehouse'
                            }).map((storeItem) => (
                                <StoreCard
                                    key={storeItem._id}
                                    className="mt-4"
                                    storeItem={storeItem}
                                    selectedStoreId={selectedStoreIdForPickup}
                                    RadioNdBtn={true}
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>
        </Drawer>
    );
};

export default StoreListDrawer;
