import React, { useState } from 'react';
import InputField from '../inputs-fields/input';
import CustomButton from '../button';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { SubscribeEmail } from '@/store/slices/actions/home-action';

const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

const GetInTouch = () => {
    const [email, setEmail] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const dispatch = useDispatch<AppDispatch>();

    const handleSubmitSusbsribe = () => {
        if (!validateEmail(email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }
        dispatch(SubscribeEmail({ email })).then((res) => {
            if (res?.payload?.data?.isSubscribe === true) {
                setEmail('');
            }
        });
    };

    return (
        <div>
            <div
                className="flex justify-center items-center px-5 @sm:object-cover bg-get-in-touch-desktop @sm:bg-get-in-touch-mobile"
                style={{
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                }}
            >
                <div className="my-[5%] text-center">
                    <div className="text-white font-bold text-24 @sm:text-16">
                        Get In Touch
                    </div>
                    <div className="text-white fs-16  my-[5%]">
                        Be the first to know about our wellness offerings.
                        Subscribe for health-centric updates and benefits.
                    </div>
                    <div className="flex w-full  gap-5 my-[5%]">
                        <div className="w-[70%]">
                            {/* <InputField placeholder="Your Email" /> */}
                            <input
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Your Email"
                                type="email"
                                value={email}
                                className="text-white w-full h-full outline-none border border-white rounded-md px-3"
                            />
                            {errorMessage && (
                                <div className="text-red-500 text-sm text-left pl-2 mt-1">
                                    {errorMessage}
                                </div>
                            )}
                        </div>
                        <div
                            onClick={handleSubmitSusbsribe}
                            className="border-white w-[30%] border-[1px] text-white p-3 rounded-md cursor-pointer"
                        >
                            Subscribe
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetInTouch;
