import { topBrandsHeader } from '@/store/slices/actions/brand-action';
import { SetBrandId } from '@/store/slices/brand-slice';
import { ClearCategoryId, SetCategoryId } from '@/store/slices/categorySlice';
import { setFirstBrandSlug } from '@/store/slices/filter-slice';
import { ClearCurrentPage } from '@/store/slices/product-slice';
import { AppDispatch } from '@/store/store';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const MakeupData = [
    { id: 1, Logo: '/images/header/makeup1.png' },
    { id: 2, Logo: '/images/header/makeup2.png' },
    { id: 3, Logo: '/images/header/makeup3.png' },
    { id: 4, Logo: '/images/header/makeup4.png' },
    { id: 5, Logo: '/images/header/makeup5.png' },
    { id: 6, Logo: '/images/header/makeup6.png' },
    { id: 7, Logo: '/images/header/makeup6.png' },
    { id: 8, Logo: '/images/header/makeup5.png' },
    { id: 9, Logo: '/images/header/makeup4.png' },
    { id: 10, Logo: '/images/header/makeup3.png' },
    { id: 11, Logo: '/images/header/makeup2.png' },
    { id: 12, Logo: '/images/header/makeup1.png' },
];

export interface IMakeup {
    categoryId: string;
    categorySlug: string;
    closeClick?: any;
}

const Makeup: React.FC<IMakeup> = ({ categoryId, closeClick }) => {
    const dispatch = useDispatch<AppDispatch>();
    const router = useRouter();
    const brandList = useSelector(
        (state: any) => state.brand_store.topBrandsHeaderData
    );

    useEffect(() => {
        dispatch(ClearCurrentPage());
        if (
            categoryId !== '' &&
            categoryId !== null &&
            categoryId !== undefined
        ) {
            let reqData = {
                page: 1,
                pageSize: 12,
                attribute: 'brand',
                firstCategoryId: categoryId,
            };
            // console.log("reqdata", reqData);
            dispatch(topBrandsHeader(reqData));
        }
    }, [categoryId]);

    const handleItemClick = (
        categoryId: string,
        brandId: string,
        slug: string
    ) => {
        dispatch(SetCategoryId({ firstCategoryId: categoryId }));
        dispatch(setFirstBrandSlug(slug));
        router.push(`/product-listing?page=1&brand=${slug}`);
    };

    return (
        <div className="flex justify-center items-center self-center w-full ">
            <div className="flex flex-wrap justify-start items-center w-full gap-[4%] py-10 border-t ">
                {brandList?.map((item: any, index: any) => (
                    <div
                        onClick={() => {
                            handleItemClick(categoryId, item._id, item.slug),
                                closeClick();
                        }}
                        key={index}
                        className="border border-[#6D6E71] rounded-2xl w-[13.3%] h-[6vw] justify-center flex items-center mb-10 cursor-pointer"
                    >
                        <Image
                            className="w-full h-full rounded-2xl object-contain object-center"
                            width={400}
                            height={400}
                            src={item.image}
                            alt={item.value}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Makeup;
