import React from 'react';
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { Tab } from '@mui/base/Tab';
import Makeup from './makeup';
import { useSelector } from 'react-redux';

interface CloseOnClick {
    closeClick?: any;
}

const TopBrandsTabs: React.FC<CloseOnClick> = ({ closeClick }) => {
    const categories = useSelector(
        (state: any) => state.category_store.categories
    );
    const defaultCategoryId = categories.length > 0 ? categories[0]._id : null;

    const filteredCategories = categories?.filter(
        (category: any) => category.name !== 'Health & Pharmacy'
    );

    return (
        <Tabs className="w-full" defaultValue={defaultCategoryId}>
            <TabsList className="mb-4 rounded-xl bg-white flex gap-4">
                {filteredCategories?.map((item: any) => (
                    <Tab
                        key={item._id}
                        slotProps={{
                            root: ({ selected, disabled }) => ({
                                className: ` ${selected ? 'text-white font-bold bg-[#275B6A] ' : 'text-[#3B3B3B] bg-transparent focus:text-white hover:bg-[#275B6A] hover:text-[#fff]'} ${
                                    disabled
                                        ? 'cursor-not-allowed opacity-50'
                                        : 'cursor-pointer border-[1px] border-[#275B6A]'
                                } w-full py-2.5 px-2 border-0 rounded-lg fs-16 `,
                            }),
                        }}
                        value={item._id}
                    >
                        {item.name}
                    </Tab>
                ))}
            </TabsList>
            {filteredCategories?.map((item: any) => (
                <TabPanel key={item._id} className="w-full" value={item._id}>
                    <Makeup
                        categoryId={item._id}
                        categorySlug={item.slug}
                        closeClick={closeClick}
                    />
                </TabPanel>
            ))}
        </Tabs>
    );
};

export default TopBrandsTabs;
