'use client';
import GetInTouch from '@/components/home/get-in-touch';
import Footer from '@/components/layouts/footer';
import Header from '@/components/layouts/header';
import { useEffect } from 'react';

export default function MainLayout({
    children,
}: {
    children: React.ReactNode;
}) {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <main className="min-h-screen items-center justify-between flex flex-col w-[100%]">
            <div className="w-full">
                <div className="fixed @sm:sticky @md:sticky w-[100%] z-10 top-[0] md:px-[3.5%] bg-white">
                    <Header />
                </div>
                {/* <Header /> */}
                <div className="@lg:mt-[14%] @sm:mt-[2%] @md:mt-[2%] mt-[12%] @2xl:mt-[11%]  w-[93%] @sm:w-[95%] mx-auto ">
                    {children}
                </div>
                <div className="w-full h-full mt-5">
                    <GetInTouch />
                </div>
            </div>

            <Footer />
        </main>
    );
}
