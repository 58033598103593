import React, { useState, useEffect } from 'react';
import TopBrandsTabs from './top-brand-tabs';
import BrandsSearch from './brand-search';

interface CloseOnClick {
    closeClick?: any;
}

const TopBrands = ({ closeClick }: CloseOnClick) => {
    const [searchResults, setSearchResults] = useState<string[]>([]);

    const handleSearch = async ({
        query,
        suggestions,
    }: {
        query: string;
        suggestions: string[];
    }) => {
        setSearchResults(suggestions);
    };

const [showBrandList, setShowBrandList] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowBrandList(true)
        }, 10)

        return () => {
            setShowBrandList(false);
        }
    }, [])

    return (
        <div className="w-full flex justify-between px-5 shadow-md py-2 rounded-md">
            <div className="w-[25%] pt-1.5">
               {showBrandList && <BrandsSearch
                        onSearch={handleSearch}
                        suggestions={searchResults}
                        closeClick={closeClick}
                    />}
            </div>
            <div className="w-[75%]">
                <TopBrandsTabs closeClick={closeClick} />
            </div>
        </div>
    );
};

export default TopBrands;
