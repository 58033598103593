import { Button as MuiButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

type ButtonProps = {
    children: React.ReactNode;
    loading?: boolean;
    onClick?: () => void;
    loadingText?: React.ReactNode;
    type?: 'button' | 'submit' | 'reset';
    outlined?: boolean;
    disabled?: boolean;
    className?: string;
};

export default function CustomButton({
    loading,
    onClick,
    outlined,
    children,
    type = 'button',
    disabled = false,
    className = '',
    loadingText = <div>&nbsp;</div>,
}: ButtonProps) {
    return (
        <MuiButton
            className={`${
                outlined
                    ? 'border-[#3B3B3B99] border-[1px] text-[#3B3B3B] font-normal hover:bg-inherit hover:text-[#3B3B3B]'
                    : loading
                      ? 'bg-[#fcab63]'
                      : 'bg-[#FF8F2F] hover:bg-[#FF8F2F] text-white font-bold'
            } w-full ${className}`}
            variant={outlined ? 'outlined' : 'contained'}
            onClick={onClick}
            disabled={loading || disabled}
            type={type}
            startIcon={
                loading && <CircularProgress size={20} sx={{ color: '#fff' }} />
            }
        >
            <div
                className="pb-2 pt-[0.5rem] buttonFont  leading-tight"
                style={{ textTransform: 'none' }}
            >
                {loading && loadingText ? loadingText : children}
            </div>
        </MuiButton>
    );
}
