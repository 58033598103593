export function createEncodedURL(
    baseURL: string,
    params: { [key: string]: string | number }
) {
    const queryParams = Object.keys(params)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join('&');
    return `/${baseURL}?${queryParams}`;
}
