'use client';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MinimizeIcon from '@mui/icons-material/Minimize';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { useRouter } from 'next/navigation';
import { getBrandsCategoryWise, getBrandsForHeader } from '@/store/slices/actions/brand-action';
import { SetCategoryId } from '@/store/slices/categorySlice';
import {
    setFirstBrandSlug,
    setHeaderFilter,
} from '@/store/slices/filter-slice';

interface Brands {
    id: string;
    name: string;
    slug: string;
    Components: string[];
}

const BrandComponent = ({ toggleDrawer }: any) => {
    const [expanded, setExpanded] = useState<string | null>();
    const dispatch = useDispatch<AppDispatch>();
    const categories = useSelector(
        (state: any) => state.category_store.categories
    );
    const { firstCategoryId } = useSelector(
        (state: any) => state.category_store.categoryId
    );
    const categoryId = firstCategoryId;
    const router = useRouter();
    const brandList = useSelector(
        (state: any) => state.brand_store.brandsCategoryWise
    );

    const filteredCategories = categories?.filter(
        (category: any) => category.name !== 'Health & Pharmacy'
    );

    useEffect(() => {
        if (
            categoryId !== '' &&
            categoryId !== null &&
            categoryId !== undefined
        ) {
            // dispatch(getBrandsForHeader(categoryId));
        dispatch(getBrandsCategoryWise(categoryId))
        }
    }, [categoryId]);

    const handleItemClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        categorySlug: string,
        slug: string
    ) => {
        dispatch(setHeaderFilter(true));
        dispatch(setFirstBrandSlug(slug));
        toggleDrawer('left', false)(e);
        router.push(
            `/product-listing?page=1&first=${categorySlug}&brand=${slug}`
        );
    };

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : null);
        };

    return (
        <div>
            {filteredCategories?.map((Brands: any) => (
                <Accordion
                    key={Brands._id}
                    expanded={expanded === `panel${Brands._id}`}
                    onChange={handleChange(`panel${Brands._id}`)}
                    className="cursor-pointer my-0 border-0 shadow-sm shadow-[#FFFFFF1A] bg-transparent text-white"
                >
                    <AccordionSummary
                        sx={{ borderColor: '#FFFFFF1A' }}
                        className=" min-h-[50px] "
                        onClick={() =>
                            dispatch(
                                SetCategoryId({ firstCategoryId: Brands._id })
                            )
                        }
                        expandIcon={
                            expanded === `panel${Brands._id}` ? (
                                <MinimizeIcon sx={{ color: '#fff' }} />
                            ) : (
                                <AddIcon sx={{ color: '#fff' }} />
                            )
                        }
                    >
                        <p
                            className="cursor-pointer font-bold text-[#fff] fs-18 py-2 "
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleDrawer('left', false);
                                router.push(
                                    '/product-listing?page=1&first=' +
                                        Brands.slug
                                );
                            }}
                        >
                            {Brands.name}
                        </p>
                    </AccordionSummary>
                    <AccordionDetails className="pt-0 mt-0">
                        <ul>
                            {brandList?.map((element: any, index: any) => (
                                <>
                                    <div
                                        onClick={(e) =>
                                            handleItemClick(
                                                e,
                                                Brands.slug,
                                                element.slug
                                            )
                                        }
                                    >
                                        <li
                                            className="cursor-pointer py-5 border-b-[1px] border-[#FFFFFF1A] capitalize"
                                            key={element._id}
                                        >
                                            {element.value?.toLowerCase()}
                                        </li>
                                    </div>
                                </>
                            ))}
                        </ul>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default BrandComponent;
