'use client';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setIPAddress } from '@/store/slices/checkout-slice';

function IPAddress() {
    const [ip, setIP] = useState('');
    const dispatch = useDispatch();

    const getData = async () => {
        const res = await axios.get('https://api.ipify.org/?format=json');
        setIP(res.data.ip);
        dispatch(setIPAddress(res.data.ip));
    };

    useEffect(() => {
        getData();
    }, []);

    return <div></div>;
}

export default IPAddress;
