'use client';
import {
    setDeviceType,
    setDeviceTypeValue,
} from '@/store/slices/checkout-slice';
import React, { useEffect } from 'react';
import { browserName, isMobile, isDesktop } from 'react-device-detect';
import { useDispatch } from 'react-redux';

export default function DeviceType() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setDeviceType(navigator.userAgent));
        if (isMobile) {
            dispatch(setDeviceTypeValue('mobile'));
        } else {
            dispatch(setDeviceTypeValue('web'));
        }
    }, []);

    return <div></div>;
}
