'use client';
import React from 'react';
import { useInput } from '@mui/base/useInput';
import { styled } from '@mui/system';
import useFlashOnError from '@/hooks/useFlashOnError';

type InputFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
    error?: { message: string };
    backgroundColor?: string;
    style?: {[key: string]: string}
};

const InputField = React.forwardRef(function CustomInput(
    props: HTMLInputElement & InputFieldProps & any,
    ref
) {
    const {error, style = {}} = props;
    const { getRootProps, getInputProps } = useInput(props);

    const inputProps = getInputProps();

    const showErrorBorder = useFlashOnError(error);

    return (
        <div {...getRootProps()} style={{ position: 'relative', ...style }}>
            <StyledInputElement
                className={"fs-18 text-black" + (showErrorBorder ? ' border-2 border-[#ff8f2f]' : '')}
                {...props}
                {...inputProps}
                ref={ref}
            />
            {props.error && (
                <div className="mt-2 fs-18 text-[#FF2323]">
                    {props.error.message}
                </div>
            )}
            {props.icon && (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '0.75rem',
                        transform: 'translateY(-50%)',
                        backgroundColor: props.backgroundColor,
                    }}
                >
                    {props.icon}
                </div>
            )}
            {props?.CalendarMonthIcon && (
                <div
                    className="@lg:hidden @lgtab:hidden @xl:hidden @2xl:hidden"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '0.75rem',
                        transform: 'translateY(-50%)',
                        backgroundColor: 'white',
                    }}
                >
                    {props?.CalendarMonthIcon}
                </div>
            )}
        </div>
    );
});

const orange = {
    100: '#FF8F2F',
    200: '#FFD2AC',
};

const grey = {
    100: '#DFE1E3',
    200: '#B0A6BD',
    300: '#000',
};

const StyledInputElement = styled('input')(
    ({ theme }) => `
  width: 100%;
 padding: 0.75rem;
  border-radius: 5px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[300]};
  background: ${theme.palette.mode === 'dark' ? grey[100] : '#fff'};
  border: 2px solid ${theme.palette.mode === 'dark' ? grey[100] : grey[100]};
 
  // &:hover {
  //   border-color: ${orange[100]};
  // }

  &:focus {
    border-color: ${orange[100]};
    box-shadow: 0 0 0 1px ${theme.palette.mode === 'dark' ? orange[200] : orange[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default InputField;
