import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { ClearSearch } from '@/store/slices/home-slice';
import { elasticSearchData } from '@/store/slices/actions/home-action';
import { useRouter } from 'next/navigation';
import InputField from '../inputs-fields/input';
import Image from 'next/image';
import { useDebounce } from '../common/useDebounce';
import { setFirstBrandSlug } from '@/store/slices/filter-slice';

const theme = createTheme({
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
    palette: {
        primary: {
            main: '#FF8F2F',
        },
    },
});

const UseAutocomplete = ({ storeId }: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const [search, setSearch] = React.useState('');
    const searchSuggestions = useSelector(
        (state: any) => state.home_store.searchSuggestions
    );
    const router = useRouter();
    const [showSuggestions, setShowSuggestions] = React.useState(false);
    const [search_items, setSearchItems] = React.useState<any>(null);

    const order: any = {};
    let count: any = 1;

    for (const key in searchSuggestions) {
        if (Array.isArray(searchSuggestions[key])) {
            order[key] = count;
            count++;
        }
    }

    const products =
        searchSuggestions?.products &&
        searchSuggestions?.products?.map((product: any) => ({
            id: product._id,
            name: `${product.name}`,
            datatype: 'Product',
            ...product,
        }));

    const brands =
        searchSuggestions?.brands &&
        searchSuggestions?.brands?.map((brand: any) => ({
            id: brand._id,
            name: `${brand.value}`,
            datatype: 'Brand',
            ...brand,
        }));

    // Map categories with type
    const category =
        searchSuggestions?.category &&
        searchSuggestions?.category?.map((category: any) => ({
            id: category._id,
            name: `${category.name}`,
            datatype: 'Category',
            ...category,
        }));

    const productVariant =
        searchSuggestions?.productVariant &&
        searchSuggestions?.productVariant?.map((productVariant: any) => ({
            id: productVariant._id,
            name: `${productVariant.title}`,
            datatype: 'Product',
            ...productVariant,
        }));

    // Function to move objects with a specific name to the top of the array
    function moveToTop(searchName: any) {
        // var allSuggestions = [
        //     ...(productVariant || []),
        //     ...(products || []),
        //     ...(brands || []),
        //     ...(category || []),
        // ];

        // Combine objects based on the order
        const allSuggestions: any = [];

        Object.keys(order).forEach((key) => {
            if (key === 'brands' && brands) {
                allSuggestions.push(...brands);
            } else if (key === 'products' && products) {
                allSuggestions.push(...products);
            } else if (key === 'productVariant' && productVariant) {
                allSuggestions.push(...productVariant);
            } else if (key === 'category' && category) {
                allSuggestions.push(...category);
            }
        });

        allSuggestions?.sort((a: any, b: any) => {
            if (a.name === searchName) return -1; // Move a to a lower index if its name matches
            if (b.name === searchName) return 1; // Move b to a lower index if its name matches
            return 0; // Leave a and b unchanged in relation to each other
        });

        setSearchItems(allSuggestions);
    }

    React.useEffect(() => {
        moveToTop(search);
    }, [searchSuggestions, search]);

    const debouncedRequest = useDebounce((callback: any) => callback(), 500);

    const handleSearchChange = (value: string) => {
        if (value) {
            setSearch(value);
            debouncedRequest(() => {
                dispatch(elasticSearchData(value));
            });
            setShowSuggestions(true);
        } else {
            setSearch('');
            dispatch(ClearSearch());
            setShowSuggestions(false);
        }
    };

    const handleSuggestionSelect = (suggestion: any) => {
        if (suggestion.datatype === 'Product') {
            if (suggestion.type === 'simple') {
                router.push(`/product-details?masterSku=${suggestion.sku}`);
                setShowSuggestions(false);
                setSearch('');
            } else {
                router.push(
                    `/product-details?masterSku=${suggestion.masterSku}&variant=${suggestion.id}`
                );
                setShowSuggestions(false);
                setSearch('');
            }
        } else if (suggestion.datatype === 'Category') {
            router.push(`/product-listing?page=1&third=${suggestion.slug}`);
            setShowSuggestions(false);
            setSearch('');
        } else if (suggestion.datatype === 'Brand') {
            dispatch(setFirstBrandSlug(suggestion.slug));
            router.push(`/product-listing?page=1&brand=${suggestion.slug}`);
            setShowSuggestions(false);
            setSearch('');
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            router.push(`/search-page?query=${search}`);
            setShowSuggestions(false);
            setSearch('');
        }
    };

    const [isInputClicked, setIsInputClicked] = React.useState(false);

    const toggleDrawer = () => {
        setIsInputClicked(!isInputClicked);
    };
    const inputRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target as Node)
            ) {
                setIsInputClicked(false);
                setShowSuggestions(false);
                setSearch('');
            }
        };

        const handleScroll = () => {
            setIsInputClicked(false);
            setShowSuggestions(false);
            setSearch('');
        };

        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <div id="HeaderSearchBar " className="w-full rounded-md  lg:flex">
                <div
                    className={`search-container SearchBar @sm:relative @md:relative @md:right-0 @md:w-full @sm:w-full @sm:right-0 rounded-md bg-[#F4F4F4]  ${
                        isInputClicked
                            ? ' open @sm:relative @sm:w-full @md:relative @lg:w-[30%] @xl:w-[30%] @2xl:w-[30%]'
                            : ' @sm:relative @md:relative @sm:w-full'
                    }`}
                    ref={inputRef}
                >
                    <InputField
                        icon={<SearchIcon />}
                        type="text"
                        className="search-input px-3 py-3.5"
                        placeholder="Search"
                        value={search}
                        onChange={(e: any) =>
                            handleSearchChange(e.target.value)
                        }
                        onKeyDown={handleKeyDown}
                        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                            e.stopPropagation();
                            setIsInputClicked(true);
                        }}
                    />
                </div>
                {showSuggestions && (
                    <div
                        ref={inputRef}
                        className="suggestions-container z-10 rounded-lg @sm:w-full @sm:right-0 h-[500px] mb-3"
                    >
                        <div className="h-full absolute shadow-md bg-white @lg:w-[30vw] @xl:w-[30vw] @2xl:w-[30vw] @sm:w-[100vw] @sm:left-0 @sm:top-[20%] w-[76vw] @lg:left-[180%] @xl:left-[181%] @2xl:left-[182%] left-[40%] top-[10%] pb-3 pl-3 rounded-lg">
                            <div className="overflow-scroll h-full">
                                {search_items?.length === 0 ? (
                                    <div className="w-full text-center mt-5 text-gray-500">
                                        No search result found.
                                    </div>
                                ) : (
                                    search_items?.map(
                                        (suggestion: any, index: any) => (
                                            <div
                                                onClick={() => {
                                                    setIsInputClicked(false);
                                                    handleSuggestionSelect(
                                                        suggestion
                                                    );
                                                }}
                                                key={index}
                                                className="suggestion cursor-pointer flex justify-between items-center gap-4 px-2 py-2 hover:bg-gray-100"
                                            >
                                                {suggestion.datatype ===
                                                    'Product' && (
                                                    <Image
                                                        width={80}
                                                        height={80}
                                                        src={
                                                            suggestion
                                                                ?.images[0]
                                                        }
                                                        alt={suggestion?.name}
                                                        className="mr-2"
                                                    />
                                                )}
                                                <span>
                                                    {suggestion.name?.length >
                                                    35
                                                        ? `${suggestion.name
                                                              ?.slice(0, 32)
                                                              ?.toLowerCase()
                                                              ?.replace(
                                                                  /(?:^|\s)\S/g,
                                                                  (char: any) =>
                                                                      char.toUpperCase()
                                                              )}...`
                                                        : suggestion.name
                                                              ?.toLowerCase()
                                                              ?.replace(
                                                                  /(?:^|\s)\S/g,
                                                                  (char: any) =>
                                                                      char.toUpperCase()
                                                              )}
                                                </span>
                                                <span className="ml-auto text-gray-500 border-1 border-[#A9A9A9] bg-[#f2f2f2] rounded-md text-sm h-fit  py-1 px-4">
                                                    {suggestion.datatype}
                                                </span>
                                            </div>
                                        )
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ThemeProvider>
    );
};

export default UseAutocomplete;
