'use client';

import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getApi, postApi } from '@/scripts/api-services';
import { SetStoreId } from '@/store/slices/product-slice';
import { setStores } from '@/store/slices/store-slice';

const LocationComponent: React.FC = memo(() => {
    const dispatch = useDispatch();
    const [location, setLocation] = useState<{
        latitude: number | string;
        longitude: number | string;
    } | null>(null);
    const [showPermissionDialog, setShowPermissionDialog] = useState(false);

    useEffect(() => {
        const getLocation = async () => {
            try {
                if ('geolocation' in navigator) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const { latitude, longitude } = position.coords;
                            setLocation({ latitude, longitude });
                        },
                        (error) => {
                            console.error(
                                'Error getting location:',
                                error.message
                            );
                        }
                    );
                } else {
                    console.error(
                        'Geolocation is not supported by your browser'
                    );
                }
            } catch (error: any) {
                console.error('Error getting location:', error.message);
            }
        };

        const showPermissionDialogIfNeeded = () => {
            if ('geolocation' in navigator && navigator.permissions) {
                navigator.permissions
                    .query({ name: 'geolocation' })
                    .then((result) => {
                        if (result.state === 'prompt') {
                            setShowPermissionDialog(true);
                        }
                    });
            }
        };

        showPermissionDialogIfNeeded();
        getLocation();
    }, []);

    const handleAllowLocation = () => {
        setShowPermissionDialog(false);
    };

    const handleDenyLocation = () => {
        setShowPermissionDialog(false);
    };

    const apiHandler = () => {
        getApi('/store').then((response) => {
            if (response) {
                localStorage.setItem('location', response.data?._id);
                dispatch(SetStoreId(response.data?._id));
            }
        });
        postApi('/admin/store')
            .then((response) => {
                if (response) {
                    dispatch(setStores(response?.data));
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        apiHandler();
    }, [location]);

    return null;
});

LocationComponent.displayName = 'LocationComponent';

export default LocationComponent;
